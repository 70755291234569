import { template as template_d17181ea9f7e4a95a623c186f189f766 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d17181ea9f7e4a95a623c186f189f766(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_da0491b5a4ec417394e064582cd8c888 } from "@ember/template-compiler";
import { gt } from "truth-helpers";
import number from "discourse/helpers/number";
import icon from "discourse-common/helpers/d-icon";
const LikesCell = template_da0491b5a4ec417394e064582cd8c888(`
  <td class="num likes topic-list-data">
    {{#if (gt @topic.like_count 0)}}
      <a href={{@topic.summaryUrl}}>
        {{number @topic.like_count}}
        {{icon "heart"}}
      </a>
    {{/if}}
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
